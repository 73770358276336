<template>
  <div id="A">
    <h1 class="brand-color-dark">Meine Dienste</h1>
    <h4>Deine Dienste, die Du Anderen zum Tausch anbietest.</h4>

    <NoDataBox v-if="exchangesFromUser.length == 0" headline="Keine Tauschangebote">Du bietest zurzeit niemanden Dienste an.<br />
        Öffne Deinen <router-link class='link' to="../dienstplan/monatsplan">Dienstplan</router-link>, um einen Dienst zum Tausch
        auszuwählen.</NoDataBox>


        <!-- {{exchanges}} -->
        
    
    <!-- <div class="flex flex-nowrap overflow-x-scroll justify-center">
      <ShiftBox
      class="w-1/3"
        determination
        :data="shift"
        v-for="shift in shifts"
        :key="shift.id"
        :hidePastShifts="true"
      ></ShiftBox>
    </div> -->
        

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
        
        <ExchangeItem  v-for="exchange in exchangesFromUser" :key="exchange.id" :exchange="exchange" :canRemove="true"></ExchangeItem>
        <!-- <div class="content-card w-full shadow-lg" >
          
        </div> -->
      </div>

      <!-- <div class="content-card">
        <div class="card-header card-header-flex">
          <p>Dienstag, den 04.05.21</p>
          <font-awesome-icon icon="cloud" class="icon color-shift-middle"/>
        </div>
        <div class="card-content">
          <div class="alert alert-brand text-center">
            <font-awesome-icon icon="check" class="icon color-success"/>

            <p class="alert-heading">
              <b>Dienst getauscht</b>
            </p>
            <p class="alert-content">
              Marcel Bels<br />
              01.01.2021 - 00:00 Uhr
            </p>
            <p class="hint">
              Wir haben deinen <a class="link">Dienstplan</a> automatisch
              aktualisiert.
            </p>
          </div>

          <div>
            <p><b>Umlaufstück</b></p>
            <p><b>4/62</b></p>
            <p>13:34 Uhr - NEU BOC</p>
            <p>15:28 Uhr - FSP BOC</p>
          </div>

          <div>
            <p><b>Umlaufstück</b></p>
            <p><b>4/62</b></p>
            <p>13:34 Uhr - NEU BOC</p>
            <p>15:28 Uhr - FSP BOC</p>
          </div>

          <hr />

          <div class="card-content-badges">
            <font-awesome-icon icon="cloud" class="icon color-shift-middle"/>
            <div class="badge badge-white">
              <i class="fas fa-map-marker"></i> MRH-S
            </div>
            <div class="badge badge-white">
              <i class="fas fa-caret-right"></i> NEU
            </div>
            <div class="badge badge-white">
              <i class="fas fa-caret-right"></i> NEU
            </div>
            <div class="badge badge-white">
              <i class="fas fa-caret-right"></i> NEU
            </div>
            <div class="badge badge-white">
              <i class="fas fa-caret-right"></i> NEU
            </div>
            <div class="badge badge-white">
              <i class="fas fa-caret-right"></i> NEU
            </div>
            <div class="badge badge-white">
              <i class="fas fa-caret-right"></i> NEU
            </div>
            <div class="badge badge-white">EBO</div>
          </div>
          <p class="commentar">
            "Suche einen Mitteldienst mit Einfahrt Btf. Merheim mit
            vergleichbarer Endzeit."
          </p>
        </div>
        <div class="card-footer">
          <a class="card-button"><font-awesome-icon icon="eye" class="icon"/></a>
        </div>
      </div> -->

      <div class="text-center" style="margin-top:50px"> 
        <small >Insgesamt {{exchangesFromUser && exchangesFromUser.length ? exchangesFromUser.length : 0}} Dienste</small>
      </div>
    </div>
    

</template>


<script>
import { mapGetters } from "vuex";

import NoDataBox from "@/components/NoDataBox";
import ServiceSituationIcon from "@/components/ServiceSituationIcon";
import ShiftDetails from "@/components/ShiftDetail";
import ShiftBox from "@/components/ShiftBox";
import ExchangeItem from "@/components/ExchangeItem";

export default {
  name: "A",
  components: {
    NoDataBox,
    ServiceSituationIcon,
    ShiftDetails,
    ShiftBox,
    ExchangeItem
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {
    this.$store.dispatch("fetchExchangesFromUser");
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      exchangesFromUser: "getExchangesFromUser",
      getWeekdayForDate: "getWeekdayForDate",
      getFormatedDate: "getFormatedDate",
      getServiceSituationIcon: "getServiceSituationIcon",
      // shifts: "getShifts",
    }),
  },
};
</script>